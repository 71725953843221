<template>
  <v-card>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Estadisticas generales</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle
      v-if="false"
      class="mb-8 mt-n5"
    >
      <span class="font-weight-semibold text--primary me-1">Total 48.5% Growth</span>
      <span>😎 this month</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="12"
          md="4"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, reactive, } from '@vue/composition-api'
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  setup() {
    const statisticsData = ref([
      {
        title: 'Usuarios',
        total: '0',
      },
      {
        title: 'Eventos',
        total: '0',
      },
      {
        title: 'Programas',
        total: '0',
      },
    ])
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const getStatistics = async () => {
      const usuarios_url = `${HTTP.defaults.baseURL}/identity-api/member`
      const events_url = `${HTTP.defaults.baseURL}/events-api/events`
      const programs_url = `${HTTP.defaults.baseURL}/programs-api/programs`
      const resp = [
        HTTP.get(usuarios_url,{ params: { Page: 1, PageSize: 1, }, }),
        HTTP.get(events_url,{ params: { Page: 1, PageSize: 1, }, }),
        HTTP.get(programs_url,{ params: { Page: 1, PageSize: 1, }, }),
      ]
      await Promise.all(resp)
      .then((values) => {
        statisticsData.value = [
          {
            title: 'Ciudadanos',
            total: `${values[0].data.pagination.total}`,
          },
          {
            title: 'Eventos',
            total: `${values[1].data.value.pagination.total}`,
          },
          {
            title: 'Programas',
            total: `${values[2].data.value.pagination.total}`,
          },
        ]
      })
      .catch(error => {
        notificaciones.color = 'error'
        notificaciones.text = 'Ha ocurrido un error mientras se cargaban los datos'
        notificaciones.snackbar = true
        cargandoTabla.value = false
      });
    }
    getStatistics()

    const resolveStatisticsIconVariation = data => {
      if (data === 'Eventos') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Usuarios') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Programas') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,
      notificaciones,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
