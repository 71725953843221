<template>
  <v-card>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-title class="align-start">
      <span>Programas por estados</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        :options="chartOptions"
        :series="chartData"
      ></vue-apex-charts>

      <div
        v-if="false"
        class="d-flex align-center"
      >
        <h3 class="text-2xl font-weight-semibold me-4">
          45%
        </h3>
        <span>Your sales perfomance in 45% 🤩 better compare to last month</span>
      </div>

      <v-btn
        v-if="false"
        block
        color="primary"
        class="mt-6"
        outlined
      >
        Details
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance, ref, reactive } from '@vue/composition-api'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'

    const chartOptions = {
      chart: {
        type: 'pie',
        toolbar: {
          show: false, // muestra los iconos para descargar
        },
      },
      labels: ['Aprobado', 'Pendiente', 'Denegado', 'Cancelado'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: '250',
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    const chartData = ref([0, 0, 0, 0])
    const getChartData = async () => {
      const aprobado_url = `${HTTP.defaults.baseURL}/programs-api/programs?Status=approved`
      const pendiente_url = `${HTTP.defaults.baseURL}/programs-api/programs?Status=pending`
      const denegado_url = `${HTTP.defaults.baseURL}/programs-api/programs?Status=denied`
      const cancelado_url = `${HTTP.defaults.baseURL}/programs-api/programs?Status=canceled`
      const resp = [
        HTTP.get(aprobado_url,{ params: { Page: 1, PageSize: 1, }, }),
        HTTP.get(pendiente_url,{ params: { Page: 1, PageSize: 1, }, }),
        HTTP.get(denegado_url,{ params: { Page: 1, PageSize: 1, }, }),
        HTTP.get(cancelado_url,{ params: { Page: 1, PageSize: 1, }, }),
      ]
      await Promise.all(resp)
      .then((values) => {
        chartData.value = [
          values[0].data.value.pagination.total,
          values[1].data.value.pagination.total,
          values[2].data.value.pagination.total,
          values[3].data.value.pagination.total,
        ]
      })
      .catch(error => {
        notificaciones.color = 'error'
        notificaciones.text = 'Ha ocurrido un error mientras se cargaban los datos'
        notificaciones.snackbar = true
        cargandoTabla.value = false
      });
    }
    getChartData()

    return {
      chartOptions,
      chartData,
      notificaciones,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
