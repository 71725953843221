<template>
  <div class="d-flex justify-content-center align-items-center main-content-jnk">
    <v-row class="text-center">
      <div style="display: flex;justify-content: center;align-items: center;height: 60vh;">
        <v-img
        style="transform: translateY(50%);"
          max-height="500"
          min-width="330"
          max-width="800"
          :src="require('@/assets/images/logos/logo.svg')"
        ></v-img>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageUnderConstruction',
}
</script>

<style>

</style>
